export const navigation = [
  {
    path: '/',
    title: 'Projekte',
    description: 'so sieht das aus',
    matchPartial: '/projekte/',
  },
  {
    path: '/leistungen',
    title: 'Leistungen',
    description: 'wie wir herangehen',
  },
  {
    path: '/ueber-uns',
    title: 'Über uns',
    description: 'was wir ziemlich gut können',
  },
  {
    path: '/fotografie',
    title: 'Fotografie',
    description: 'wie wir die Welt sehen',
    matchPartial: '/fotografie/',
  },
  {
    path: '/kontakt',
    title: 'Kontakt',
    description: 'wollen wir sprechen?',
  },
];
