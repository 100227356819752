import { Link } from 'gatsby';
import * as React from 'react';
import { useState } from 'react';
import styled from '@emotion/styled';

import { Menu } from './Menu';
import { navigation } from '../navigation';

const Header = ({ siteTitle }: any) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <Menu
        isOpen={menuOpen}
        onClose={() => setMenuOpen(false)}
        items={navigation}
      />

      <HeaderBar>
        <HeaderContent>
          <MenuButton
            onMouseEnter={() => setMenuOpen(true)}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <MenuIcon />
          </MenuButton>

          <div style={{ flex: 1 }} />

          <SiteTitle>
            <Link
              to="/"
              style={{
                textDecoration: 'none',
              }}
            >
              {siteTitle}
            </Link>
          </SiteTitle>
        </HeaderContent>
      </HeaderBar>
    </>
  );
};

export default Header;

const HeaderBar = styled.header`
  // position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  height: 3rem;

  @media (max-width: 800px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.6);
    z-index: 10;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 1rem;
  height: 100%;
`;

export const SiteTitle = styled.h1`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 1rem;
  color: #4a4a4a;
  letter-spacing: 0.54px;
  margin: 0;

  a {
    text-decoration: none;
    color: unset;
  }
`;

const MenuButton = styled.div`
  position: relative;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 3rem;
  width: 3rem;

  @media (max-width: 800px) {
    left: 1rem;
  }
`;

const Icon = styled.svg`
  @media (max-width: 800px) {
    width: 20px;
  }
`;

const MenuIcon = (props: any) => (
  <Icon width={38} height={24} viewBox="0 0 38 24" {...props}>
    <g fill="currentColor" fillRule="evenodd">
      <path d="M38 0 v 4 H0 V0z M38 10v4H0V10z M38 20v4H0v-4z" />
    </g>
  </Icon>
);
