import styled from '@emotion/styled';
import { Link } from 'gatsby';
import * as React from 'react';

import { ContentBlock } from './Blocks';
import { SiteTitle } from './Header';
import { navigation } from '../navigation';

export function Footer(props: { title: string }) {
  return (
    <ContentBlock>
      <FooterBlock>
        <FooterHead>Wollen wir sprechen?</FooterHead>
        <p>
          <strong>T:</strong> 0951 / 519362 - 14 | 15 <br />
          <strong>M:</strong>{' '}
          <a href="mailto:hello@anywhereigo.com">hello@anywhereigo.com</a>
        </p>

        <FooterTitle>
          <Link
            to="/"
            style={{
              textDecoration: 'none',
            }}
          >
            {props.title}
          </Link>
        </FooterTitle>

        <FooterNavigation>
          {navigation.map(item => (
            <FooterItem key={item.path}>
              <FooterLink to={item.path}>{item.title}</FooterLink>
            </FooterItem>
          ))}
        </FooterNavigation>
      </FooterBlock>
      <LegalLinks>
        <FooterLink to="/impressum">Impressum &amp; Datenschutz</FooterLink>
      </LegalLinks>
    </ContentBlock>
  );
}

const FooterBlock = styled.footer`
  padding: 3rem 0.5rem 0;
  border-top: 1px solid #979797;
  border-bottom: 1px solid #979797;

  h2,
  p {
    margin: 0;
  }

  p {
    margin-bottom: 4rem;
  }

  a {
    color: inherit;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }

  ::before,
  ::after {
    display: table;
    content: '';
    clear: both;
  }

  @media (max-width: 800px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

const FooterTitle = styled(SiteTitle)`
  float: left;
  line-height: 2rem;

  @media (max-width: 800px) {
    float: none;
  }
`;

const FooterNavigation = styled.div`
  float: right;

  @media (max-width: 800px) {
    display: none;
  }
`;

const FooterItem = styled('span')`
  font-size: 1rem;
  line-height: 2rem;

  & + &::before {
    content: ' · ';
  }
`;

const FooterLink = styled(Link)`
  text-decoration: none;
  color: #3c434c;

  :hover {
    text-decoration: underline;
  }
`;

const LegalLinks = styled.div`
  clear: both;
  text-align: right;
  font-size: 0.7rem;
  padding-right: 0.5rem;

  @media (max-width: 800px) {
    margin-top: 1rem;
    padding-right: 2rem;
  }
`;

const FooterHead = styled.h2`
  position: relative;
  width: auto;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 800;
  color: #3c434c;
  line-height: 3rem;

  :before {
    position: absolute;
    z-index: -1;
    content: '';
    height: 5px;
    bottom: 0.8rem;
    left: 0;
    right: 0;
    background: #18c6ff;
  }
`;
