import * as React from 'react';

interface Props {
  onTrigger: (event: MouseEvent | TouchEvent) => void;
  [prop: string]: any;
}

export class ClickOutside extends React.Component<Props> {
  element: null | HTMLElement = null;

  handleClick = (event: MouseEvent | TouchEvent) => {
    if (this.element == null) return;

    if (event.target instanceof Node) {
      if (!this.element.contains(event.target)) {
        this.props.onTrigger(event);
      }
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, true);
    document.addEventListener('touchstart', this.handleClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, true);
    document.removeEventListener('touchstart', this.handleClick, true);
  }

  render() {
    const { onTrigger, ...extraProps } = this.props;

    return <span ref={element => (this.element = element)} {...extraProps} />;
  }
}
