import * as React from 'react';

import { css } from '@emotion/core';
import styled from '@emotion/styled';

export function ContentBlock(props: React.HTMLProps<HTMLDivElement>) {
  return (
    <ContentStage>
      <Content {...props} />
    </ContentStage>
  );
}

export const ContentStage = styled.div`
  clear: both;
  padding: 2rem 5rem;
  padding-top: 0;
  overflow: hidden;

  @media (max-width: 800px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Content = styled.div`
  margin: 0 auto;
  max-width: 1115px;
`;

export const TextBlock = styled(ContentBlock)`
  padding: 1rem 4rem;

  @media (max-width: 800px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

export function StageBlock(props: any) {
  const { color, children, ...extraProps } = props;

  return (
    <Stage color={props.color} {...extraProps}>
      <Content css={{ paddingBottom: 0 }}>{props.children}</Content>
    </Stage>
  );
}

export function StageTextBlock(props: any) {
  return (
    <Stage color={props.color}>
      <TextBlock css={{ position: 'relative', paddingBottom: 0 }}>
        <svg
          css={{
            position: 'absolute',
            zIndex: 0,
            left: 0,
            top: 0,
            width: '30%',
            maxWidth: 300,
          }}
          viewBox="0 0 329 292"
        >
          <path
            d="M297.528.862l30.61 35.666-296.666 254.61-30.61-35.666z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </svg>

        <div css={{ zIndex: 1, position: 'relative' }}>{props.children}</div>
      </TextBlock>
    </Stage>
  );
}

export const Stage = styled.div<{ color?: string }>`
  padding: 2rem 3rem;
  background: ${props => (props.color ? props.color : '#fbf9e8')};
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 3rem;

  @media (max-width: 800px) {
    padding: 0;
    margin-top: 3rem;
    // padding-left: 2rem;
    // padding-right: 2rem;
  }

  img {
    display: block;
    margin: 0;
  }
`;

export const Columns = styled.div`
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const Column = css`
  float: left;
  width: 50%;
  flex-shrink: 0;
  box-sizing: border-box;

  @media (max-width: 800px) {
    width: 100%;
    clear: both;
  }
`;

export const Left = styled.div`
  ${Column};
  padding-right: 1rem;

  @media (max-width: 800px) {
    padding-right: 0;
    order: 2;
  }
`;

export const Right = styled.div`
  ${Column};
  padding-left: 1rem;

  @media (max-width: 800px) {
    padding-left: 0;
    order: 1;
  }
`;
