import styled from '@emotion/styled';
import { Link } from 'gatsby';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { animated, useChain, useSpring, useTrail } from 'react-spring';

import { HeaderContent } from './Header';
import { ClickOutside } from './ClickOutside';

export function Menu(props: {
  isOpen: boolean;
  onClose(): void;
  items: Array<{
    title: string;
    path: string;
    description: string;
    matchPartial?: string;
  }>;
}) {
  const menuOpen = props.isOpen;

  const springRef = useRef(null);
  const styles = useSpring({
    ref: springRef,
    from: {
      transform: 'translateY(-100%)',
      opacity: 0,
    },
    to: {
      transform: `translateY(${menuOpen ? '0' : '-100'}%)`,
      opacity: menuOpen ? 1 : 0,
    },
    config: {
      clamp: true,
      tension: 230,
    },
  });

  const listRef = useRef(null);
  const listStyles = useTrail(props.items.length, {
    ref: listRef,

    from: {
      opacity: 0,
      transform: 'translateY(-20px)',
    },
    to: {
      opacity: menuOpen ? 1 : 0,
      transform: `translateX(${menuOpen ? '0' : '-20'}px)`,
    },
    config: {
      clamp: true,
      tension: 230,
    },
  });

  useChain(menuOpen ? [springRef, listRef] : [listRef, springRef], [0, 0.2]);

  const activeStyle = {
    textDecoration: 'underline',
    textDecorationColor: 'rgba(255, 255, 255, 0.4)',
  };

  return (
    <ClickOutside onTrigger={props.onClose}>
      <Block
        onMouseLeave={props.onClose}
        style={{
          ...styles,
          pointerEvents: menuOpen ? undefined : 'none',
        }}
      >
        <HeaderContent>
          {/* <CloseIcon onClick={props.onClose} /> */}
          <NavigationList>
            {props.items.map((item, index) => (
              <animated.li key={item.path} style={listStyles[index]}>
                <Link
                  getProps={options => {
                    const partialMatch =
                      item.matchPartial !== undefined &&
                      options.location.pathname.indexOf(item.matchPartial) ===
                        0;

                    return options.isCurrent || partialMatch
                      ? { style: activeStyle }
                      : {};
                  }}
                  to={item.path}
                >
                  {item.title} <aside>{item.description}</aside>
                </Link>
              </animated.li>
            ))}
          </NavigationList>
        </HeaderContent>
      </Block>
    </ClickOutside>
  );
}

const CloseIcon = (props: any) => (
  <svg width={30} height={30} {...props}>
    <g fill="#FFF" fillRule="evenodd">
      <path d="M29.698 26.87l-2.828 2.828L0 2.828 2.828 0z" />
      <path d="M26.87 0l2.828 2.828-26.87 26.87L0 26.87z" />
    </g>
  </svg>
);

const Block = styled(animated.div)`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  background: #4a4a4a;
  color: #fff;
`;

const NavigationList = styled.ul`
  margin-left: 12rem;
  margin-top: 3rem;
  margin-bottom: 3rem;

  list-style: none;

  @media (max-width: 800px) {
    margin-left: 2rem;
    margin-right: 1rem;
  }

  a {
    font-weight: 700;
    color: #fff;
    text-decoration: none;

    :hover {
      text-decoration: underline;
      text-decoration-color: rgba(255, 255, 255, 0.4);
    }
  }

  aside {
    display: inline;
    ::before {
      content: '_ ';
    }
    font-weight: 400;
  }
`;
