import './layout.css';

import { graphql, StaticQuery } from 'gatsby';
import * as React from 'react';
import Helmet from 'react-helmet';

import { Footer } from './Footer';
import Header from './Header';
import { Global, css } from '@emotion/core';

const Layout = ({ children }: any) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Global
          styles={css`
            @media (max-width: 800px) {
              html {
                font-size: 14px;
              }
            }
          `}
        />
        <Helmet
          title={data.site.siteMetadata.title}
          meta={
            [
              // { name: 'description', content: 'Sample' },
              // { name: 'keywords', content: 'sample, something' },
            ]
          }
        >
          <html lang="de" />
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />

        {children}

        <Footer title={data.site.siteMetadata.title} />
      </>
    )}
  />
);

export default Layout;
